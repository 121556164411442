import { Button } from '@mui/material';
import CustomTypography from 'components/Typography';

interface ICustomButton {
  children: string;
  disabled?: boolean;
  iconPath?: string;
  width?: string;
  height?: string;
  backgroundColor?: string;
  border?: string;
  margin?: string;
  onClick: () => void;
}
const CustomButton = ({
  children,
  disabled,
  iconPath,
  width,
  height,
  backgroundColor,
  border,
  margin,
  onClick,
}: ICustomButton) => {
  return (
    <Button
      sx={{
        margin,
        padding: '11px 24px',
        height: height || 'fit-content',
        width: width || 'fit-content',
        backgroundColor: backgroundColor || '#ffbf30',
        borderRadius: '8px',
        border: border || 'none',
        textTransform: 'none',
        '&:hover': {
          backgroundColor: backgroundColor || '#ffbf30',
        },
        '&:disabled': {
          opacity: '0.5',
        },
      }}
      startIcon={iconPath && <img src={iconPath} alt='plus icon' />}
      onClick={onClick}
      disabled={disabled}
    >
      <CustomTypography
        fontSize='16px'
        lineHeight='18px'
        color='#000000'
        fontWeight='600'
      >
        {children}
      </CustomTypography>
    </Button>
  );
};
export default CustomButton;
