import * as React from 'react';
import {Grid, Box, Container, Typography, TextField} from '@mui/material';
import intro from '../../../../images/onboarding-intro.svg';
import connected from '../../../../images/connected.svg';
import {PrimaryActionButton} from '../components/PrimaryActionButton';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import notForMe from '../../../../images/not-for-me-line.png';
interface Props {
  changeStep (val: number, index: boolean): any;
  stepValue: number
}

export default function Intro({stepValue, changeStep} : Props) {
  const theme = useTheme();
  const {
    allergies,
    diets,
    selectedAllergies,
    selectedDiets,
    username,
    toggleSelectedAllergy,
    toggleSelectedDiet,
    setUsernameHandler,
  } = useDietaryPreferences();

  const [name, setName] = useState(username);

  const headerProps = {
    mt: 1,
  }

  const imageProp = {
    height: '47.5px',
    width: '47.5px',
    display: 'inline-block'
  }

  const gridProps = {
    display: 'inline-grid',
    justifyContent: 'right',
    justifyItems: 'center',
    alignItems: 'center',
  }

  const typoProps = {
    display: 'inline-grid',
    alignItems: 'center',
  }

  const selectedTabProp = {
    backgroundColor: theme.palette.secondary.main, 
    borderRadius: 50, 
    color: theme.palette.primary.lighter,
    textAlign: 'center',
    width: '100px',
    padding: .5,
    mt:1
  }

  const introProperties = {
    width: '100%',
    position: 'absolute',
    zIndex: 99999,
    ml:-3,
    backgroundImage: `url(${intro})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundColor: '#FFF'
  }


  const notForMeProp = {
    top: 0,
    left: '50%',
    marginLeft: '-24px',
    position: 'absolute',
  }

  const introContent = {
     height: {
      sm: 450,
      xs: 300
    },
    justifyContent: 'right',
  }

  const intro2Props = {
    width: '100%',
    backgroundColor: theme.palette.grey[0]
  }

  const formButton = {
    width: '100%', 
    display: 'flex-box',
    flexDirection: 'column', 
    textAlign: "center"
  }

  const intro2Text = {
    alignContent: 'center',
    display: 'inline-grid'
  }

  const saveName = () => {
    setUsernameHandler(name);
    changeStep(1, false);
  }

  return (
    <Box>
      <Container sx={headerProps}>
        <Box>
           <Typography variant="h3" mt={1.028}>Meet Goldi</Typography>
        </Box>
        <Grid container mt={2}>
          <Grid item xs={7} sx={typoProps}>
           <Typography variant="h4" mt={1.028}>Personalized</Typography>
           <Typography variant="subtitle1">The easiest way for you to order food that is Just Right</Typography>
          </Grid>
          {allergies && <Grid item xs={5} sx={gridProps}>
            <img src={'https://firebasestorage.googleapis.com/v0/b/goldi-fe3e4.appspot.com/o/images%2Fgoals%2Fwhole_30_filled.png?alt=media'} style={imageProp} />
            <Typography color={theme.palette.secondary.light} sx={selectedTabProp} variant='subtitle2'>Whole 30</Typography>
          </Grid>}
          </Grid>
            <Grid container mt={2}>
             <Grid item xs={7} sx={typoProps}>
               <Typography variant="subtitle1">Simply tell Goldi how you want to eat</Typography>
            </Grid>
            {diets && <Grid item xs={5} sx={gridProps}>
               <Box sx={{position:'relative'}} textAlign='center'>
                  <img src={'https://firebasestorage.googleapis.com/v0/b/goldi-fe3e4.appspot.com/o/images%2Fallergies%2Fonion_filled.png?alt=media'} style={imageProp}  />
                  {/**<Box sx={notForMeProp}><img src={notForMe} /></Box>**/}
                  <Typography color={theme.palette.secondary.light} sx={selectedTabProp} variant='subtitle2'>Onions</Typography>
                </Box>
            </Grid>}
        </Grid>
      </Container>
      <Box mt={4} sx={introProperties} maxWidth='sm'>
        <Grid container mt={2} sx={introContent}>
         <Grid item xs={6} sm={8}>
         </Grid>
          <Grid item xs={6} sm={4} sx={{textAlign: 'right', pr: 2, display: 'inline-grid', alignContent: 'center'}}>
          <Typography variant="h4">Customized</Typography>
          <Typography variant="subtitle1"  mt={3}>Goldi recommends restaurants and menu items to fit your needs</Typography>
          </Grid>
        </Grid>
        <Box sx={intro2Props} mb={2}  maxWidth='sm'>
        <Grid container mt={-1}>
          <Grid item xs={6} p={2} sx={intro2Text}>
           <Typography variant="h4" mt={5}>Connected</Typography>
           <Typography variant="subtitle1" mt={5}>Goldi makes it easy for everyone to order what they want</Typography>
          </Grid>
           <Grid item xs={6} textAlign="right">
            <img src={connected} style={{width: '100%'}} />
          </Grid>
          </Grid>
          <Box p={3}>
            <Box my={2} sx={formButton}>
            <PrimaryActionButton title={'Select Preferences'} onClick={() => { saveName() }} />
            </Box>
          </Box>
      </Box>
      </Box>
    </Box>
  );
}
