import { Box, Dialog, Divider, Stack, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import expand from 'images/icons/expand.svg';
import { MenuItem } from 'interfaces/MenuInterface';
import { MenuItemDetail } from 'modules/explorer/MenuItemDetail';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useState } from 'react';
import { useCart } from '../context/CartContext';
import { formatCentsToDollars, getMatchingCustomizations } from '../util/util';
interface Props {
  menuItem: MenuItem;
}

export const MenuItemCard = ({ menuItem }: Props) => {
  const { id, title, description, price: initialPrice, menuItemCustomizations } = menuItem;
  const [price, setPrice] = useState(initialPrice);
  const [isLess, setIsLess] = useState(true);
  const { isInCart, addToCart, removeFromCart } = useCart();
  const { enabledAllergies, enabledDiets } = useDietaryPreferences();
  const filteredCustomizations = getMatchingCustomizations(
    menuItemCustomizations,
    {
      enabledAllergies,
      enabledDiets,
    }
  );

  const useStyles = makeStyles({
    paper: {
      position: "absolute",
      bottom: '-50px',
      width: '100%',
      paddingBottom: '70px',
      borderRadius: '0px!important'
    }
  });
  const classes = useStyles();
  // Select default options for each customization
  const initialCustomizationOptinIds = filteredCustomizations.reduce(
    (acc, customization) => {
      const { id, menuItemCustomizationOptions } = customization;
      const defaultModifierIds = menuItemCustomizationOptions
        .filter((option) => option.defaultModifier)
        .map((opt) => opt.id as string);
      acc = [...acc, ...defaultModifierIds];
      return acc;
    },
    [] as string[]
  );
  const [selectedCustomizationOptionIds, setSelectedCustomizationOptionIds] =
    useState<string[]>(initialCustomizationOptinIds);

  const handleAddItemToOrder = (price: number, quantity: number) => {
    // Only include the customizations with the selected customization option
    const customizations = filteredCustomizations
      .filter(({ menuItemCustomizationOptions }) =>
        menuItemCustomizationOptions.find(({ id }) =>
          selectedCustomizationOptionIds.includes(id ?? '')
        )
      )
      .map((customization) => ({
        ...customization,
        menuItemCustomizationOptions: getMatchingCustomizations(
          customization.menuItemCustomizationOptions,
          {
            enabledAllergies,
            enabledDiets,
          }
        ).filter(({ id }) => selectedCustomizationOptionIds.includes(id ?? '')),
      }));

    addToCart({
      userId: '1',
      item: {
        menuItem,
        customizations,
        price,
        quantity,
        size:quantity
      },
    });
  };

  const handleOnTapped = (menuItem: MenuItem) => {
    // TODO: - toggle
    if (isInCart(menuItem)) {
      removeFromCart({
        userId: '1',
        item: {
          menuItem,
          customizations: [],
          price: 0,
          quantity: 0,
          size:0
        },
      });
    }
  };


  const dialogProperties = {
    bottom: '-20%',
    height: '90%',
    width: '100%',
    top: 'auto'
  }

  return (
    <>
    <Stack key={id} direction='column'>
      <Stack
        direction='row'
        alignItems='top'
        sx={{ cursor: 'pointer' }}
        onClick={() => {
          // onSelect(menuItem);
          setIsLess((isLess) => !isLess);
        }}
      >
        {/* <MenuItemImage menuItem={menuItem} /> */}
        <Stack alignItems='start'>
          
          <Typography fontWeight={700}  variant="subtitle1">
            {title}
          </Typography>
          
          <Typography variant="caption">
            {truncateString(description ?? '', 500)}
          </Typography>
          
        </Stack>
        <Box sx={{ flex: 1 }} />
        <Stack spacing={1} alignItems='center'>
          <Typography fontWeight={700} variant="subtitle1">
                {formatCentsToDollars(price)}
              </Typography>
          <img
            src={expand}
            style={{maxWidth: '30px', marginTop: 30}}
            onClick={() => {
              handleOnTapped(menuItem);
            }}
          />
        </Stack>
      </Stack>

      <Dialog classes={{ paper: classes.paper }} open={!isLess} onClose={() => setIsLess(true)}><MenuItemDetail 
        menuItem={menuItem} 
        onClose={() => setIsLess(true)}
        selectedCustomizationOptionIds={selectedCustomizationOptionIds}
        setSelectedCustomizationOptionIds={setSelectedCustomizationOptionIds}
        filteredCustomizations={filteredCustomizations}
        handleAddItemToOrder={handleAddItemToOrder}
        setCurrentPrice={setPrice}
      /></Dialog>
    </Stack>
    <Divider />
    </>
  );
};

function truncateString(str: string, num: number) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}
