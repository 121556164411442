import { Stack, Typography } from '@mui/material';
import NoMealsIcon from '@mui/icons-material/NoMeals';
import { useCart, CartContextProvider } from '../context/CartContext';
import { UserOrderItem } from './UserOrderItem';
import { useEffect } from 'react';
import { UserOrderDetails } from './UserOrderDetails';

const OrderDetail = (Props:any) => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <Stack sx={{ pt: 4 }}>
      <CartContextProvider>
        <UserOrderDetails isEditable={Props.isEditable} />
      </CartContextProvider>
    </Stack>
  );
};

export default OrderDetail;
