import {
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Stack,
    Typography,
    Alert
} from '@mui/material';
import { Box } from '@mui/system';
import {
    MenuItemCustomization,
    MenuItemCustomizationOption,
} from 'interfaces/MenuInterface';
import React from 'react';
import { makeStyles } from '@mui/styles';
import {
    formatCentsToDollars,
    getMatchingCustomizations,
} from '../ExploreRestaurant/util/util';
import { useDietaryPreferences } from 'providers/DietaryPreferenceProvider';
import { useEffect, useState } from 'react';

const useStyles = makeStyles(() => ({
root: {
    '&$checked': {
    color: '#F7B523',
    },
},
checked: {},
}));

const MenuItemCustomizationView: React.FC<{
    // preferences?: Preference
    customization: MenuItemCustomization;
    selectedCustomizationOptionIds: string[];
    selectedOnly: boolean;
    onToggle: (option: MenuItemCustomizationOption) => void;
    setIndividualSelectedCustomizationOptionIds: (key: string, value: any) => void;
    individualSelectedCustomizationOptionIds: any;
    individualErrors: any;
  }> = ({
    // preferences,
    customization,
    onToggle,
    selectedOnly,
    selectedCustomizationOptionIds,
    setIndividualSelectedCustomizationOptionIds,
    individualSelectedCustomizationOptionIds,
    individualErrors
  }) => {
    const classes = useStyles();
    const { enabledAllergies, enabledDiets } = useDietaryPreferences();
    const { title, menuItemCustomizationOptions, id } = customization;
    const filteredOptions = getMatchingCustomizations(menuItemCustomizationOptions, {
        enabledAllergies,
        enabledDiets,
    });
    const [error, setError] = useState('');
    const [minMaxString, setMinMaxString] = useState('');
    
    useEffect(() => {
      const tempSelections:any = [];
      filteredOptions.map(val => {
        if(val.id && selectedCustomizationOptionIds.indexOf(val.id) > -1) {
          tempSelections.push(val.id);
        }
      });
      if(id) {
        setIndividualSelectedCustomizationOptionIds(id, tempSelections);
      }
    }, [selectedCustomizationOptionIds]);

    useEffect(() => {
      constructMinMaxString(customization);
    })

    const constructMinMaxString = (customization: MenuItemCustomization) => {
        const { minPermitted, maxPermitted } = customization;
        if(filteredOptions.length < minPermitted) customization.minPermitted = filteredOptions.length;
        if(filteredOptions.length < maxPermitted) customization.maxPermitted = filteredOptions.length;
        if (minPermitted && maxPermitted) {
          if (minPermitted === maxPermitted) {
            setMinMaxString(`: Select ${minPermitted} ${minPermitted === 1 ? 'option' : 'options'}`);
            return;
          }
          setMinMaxString(`: Select ${minPermitted} to ${maxPermitted} options`);
          return;
        }
        if (minPermitted) {
          setMinMaxString(`: Select at least ${minPermitted} ${minPermitted === 1 ? 'option' : 'options'}`);
          return;
        }
        setMinMaxString(`: Select up to ${maxPermitted} ${maxPermitted === 1 ? 'option' : 'options'}`);
        return;
    };

    useEffect(() => {
      if(individualErrors) {
        setError(individualErrors[individualErrors.length - 1])
      }
    }, [individualErrors]);

    const checkSelections = (option: any) => {
      setError("");
      if(id && individualSelectedCustomizationOptionIds[id] && individualSelectedCustomizationOptionIds[id][individualSelectedCustomizationOptionIds[id].length - 1].indexOf(option.id) == -1) {
        if(individualSelectedCustomizationOptionIds[id][individualSelectedCustomizationOptionIds[id].length -1 ].length >= customization.maxPermitted) {
          setError("Only " . concat(customization.maxPermitted.toString(), " selection(s) allowed!"));
          return;
        }
      }
      onToggle(option);
    }

    return (
      <Stack sx={{ my: 4 }}>
        <Typography sx={{ fontWeight: 'bold' }}>
          {title}
          {(!!customization.minPermitted || !!customization.maxPermitted) && minMaxString}
        </Typography>
        {error && <Alert severity="error" sx={{my:2}}>{error}</Alert>}
        {/* <Text style={styles.subtext}>{description}</Text> */}
        {filteredOptions.map((option, index) => {
          const { id, title, price } = option;
          const isSelected = selectedCustomizationOptionIds.includes(id!);
          return (
            <Stack key={index}>
              <Stack direction='row' alignItems='center'>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={isSelected}
                        classes={{
                          root: classes.root,
                          checked: classes.checked,
                        }}
                        onChange={() => checkSelections(option)}
                      />
                    }
                    sx={{}}
                    label={
                      <Typography variant='body2'
                        sx={{ fontWeight: isSelected ? 'bold' : 'normal' }}
                      >
                        {title}
                      </Typography>
                    }
                  />
                </FormGroup>
                <Box sx={{ flex: 1 }} />
                <Box sx={{display: 'flex'}}>
                  <Typography variant='body2'>+</Typography>
                  <Typography variant='body2' sx={{ml:.5}}>{formatCentsToDollars(price)}</Typography>
                </Box>
              </Stack>
              <Divider />
              {/* {index != (menuItem.availableOptions.length ?? 0) - 1 && <View style={styles.separator}></View>} */}
            </Stack>
          );
        })}
      </Stack>
    );
  };
  
  export default MenuItemCustomizationView;