import { auth } from 'firebase';
import {
  signInWithEmailAndPassword,
  sendPasswordResetEmail,
  signOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  getAuth, 
  getIdToken, 
  isSignInWithEmailLink,
  signInWithEmailLink,
  updatePassword,
} from 'firebase/auth';

import type { User } from 'firebase/auth';

export interface IAuthService {
  [key: string]: any; //TODO: define interface
}

export enum ROLES {
  USER = 'user',
  MERCHANT = 'merchant',
  ADMIN = 'admin',
}

export enum ROLE_TYPES {
  USER = 'User',
  SUPER_ADMIN = 'SuperAdmin',
  MERCHANT_ADMIN = 'MerchantAdmin',
  RESTAURANT_MANAGER = 'RestaurantManager',
}

export const login = async (
  email: string,
  password: string,
  rememberMe: boolean
) => {
  setPersistence(
    auth,
    rememberMe ? browserLocalPersistence : browserSessionPersistence
  );

  return signInWithEmailAndPassword(auth, email, password);
};

export const logout = () => {
  signOut(auth);
  window.location.assign('/');
};

export const sendResetPasswordEmail = async (email: string) => {
  try {
    sendPasswordResetEmail(auth, email);
  } catch (error) {
    console.error(error);
  }
};

export const verifyResetPasswordCode = async (code?: string) => {
  if (!code) return false;
  try {
    await verifyPasswordResetCode(auth, code);
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
};

export const resetPassword = async (code: string, newPassword: string) => {
  try {
    await confirmPasswordReset(auth, code, newPassword);
  } catch (error) {
    console.error(error);
  }
};

// TODO: this doesn't need to be async
export const getCurrentUser = (): User | null => {
  return getAuth().currentUser;
}

// TODO: refactor this into a provider
export const getCurrentUserRole = async (): Promise<any> => {
  const { currentUser } = getAuth();
    
    if (currentUser) {
      try {
        const { claims } = await currentUser.getIdTokenResult();
        const { role, roleType } = claims;
        return {
          role,
          roleType,
        };
      } catch (e) {
        AuthService.logout();
      }
      
    } else {
      AuthService.logout();
    }
}

export const getToken = async (): Promise<string | undefined> => {
    const { currentUser } = getAuth();
    
    if (currentUser) {
      try {
        const tokenResult = await currentUser.getIdTokenResult();
        // console.log({tokenResult});
        return getIdToken(currentUser);
      } catch (e) {
        AuthService.logout();
      }
      
    } else {
      AuthService.logout();
    }
}

export const isAdmin = async (): Promise<boolean> => {
  const { role } = await getCurrentUserRole();
  return role === ROLES.ADMIN;
}

export const signInWithLink = async (link: string) => {
  const encodedEmail = new URL(link, ).searchParams.get('email');
  const decodedEmail = atob(encodedEmail || '');
  return signInWithEmailLink(auth, decodedEmail, link);
}

export const isValidSignInLink = (link: string) => {
  return isSignInWithEmailLink(auth, link);
}

export const changePassword = async (password: string) => {
  const currentUser = getCurrentUser();
  if (currentUser) {
    await updatePassword(currentUser, password);
  }
}

const AuthService = {
  login,
  logout,
  sendResetPasswordEmail,
  verifyResetPasswordCode,
  resetPassword,
  getCurrentUser,
  getToken,
  getCurrentUserRole,
  isAdmin,
  signInWithLink,
  isValidSignInLink,
  changePassword,
};

export default AuthService;
