import { Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import logo from '../../../../images/Goldi-Animation.svg';
import GetStarted from './GetStarted';

interface Props {
  setVideoPlayed (val: boolean) : any;
}

const Splash = ({setVideoPlayed} : Props) => {
  const [checked, setChecked] = useState(false);
  const theme = useTheme();

  const splash_screen = {
    backgroundColor: theme.palette.primary.lighter,
    height: '100vh',
    width: '100%',
    display: 'flex'
  }

  useEffect(() => {
    setTimeout(() => {
      setChecked(true);
    }, 1000);
  }, []);

  return (
    <Box sx={splash_screen}>
      <img style={{margin: 'auto'}} src={logo} />
          {checked && <GetStarted setVideoPlayed={setVideoPlayed} />}
    </Box>
  );
}

export default Splash;
