import { Box, ButtonBase, Typography, Stack, Grid, Dialog } from '@mui/material';
import logo from '../../../../images/Goldi-Animation-white.svg';
import {SecondaryActionButton} from './SecondaryActionButton';
import {DefaultActionButton} from './DefaultActionButton';
import { useState } from 'react';
import { makeStyles } from '@mui/styles';
import UserAuthLogin from '../../Auth/Login';

const style = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  textAlign: 'center',
  display: 'block',
  width: '100%'
}

interface Props {
  setVideoPlayed (val: boolean) : any;
}

function GetStarted({setVideoPlayed} : Props) {
  const [isLess, setIsLess] = useState(true);
  const useStyles = makeStyles({
    paper: {
      position: "absolute",
      bottom: '-50px',
      width: '100%',
      paddingBottom: '70px',
      borderRadius: '0px!important'
    }
  });
  const classes = useStyles();

  return (
    <Box sx={style}>
      <Grid container>
        <Grid item xs={12} mb={'20px'}>
          <SecondaryActionButton title={'Get Started'} onClick={() => setVideoPlayed(true)} />
        </Grid>
        {/**<Grid item xs={12}  mb={'20px'}>
          <Dialog classes={{ paper: classes.paper }} open={!isLess} onClose={() => setIsLess(true)}>
            <UserAuthLogin onClose={() => setIsLess(true)} />
          </Dialog>
          <DefaultActionButton title={'Access My Account'} onClick={() => { setIsLess(false) }} />
        </Grid>**/}
      </Grid>
    </Box>
  );
}

export default GetStarted;
