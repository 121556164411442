import NoMealsIcon from '@mui/icons-material/NoMeals';
import { Box, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { MenuItem } from 'interfaces/MenuInterface';
import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PrimaryActionButton } from '../../ExploreRestaurant/components/PrimaryActionButton';
import { MenuItemCard } from '../components/MenuItemCard';
import { useCart } from '../context/CartContext';

export const RestaurantMenuList: React.FC<{
  menuItems: MenuItem[];
}> = ({ menuItems }) => {
  const { isInCart, addToCart, cart, removeFromCart } = useCart();  

  const navigate = useNavigate();
  const { restaurantId } = useParams();
  const theme = useTheme();
  const goToOrder = (id: number) => {
    if(restaurantId) {
      navigate('/explore/' . concat(restaurantId, `/cart/${id}`));
    }
  }

   const boxProperties = {
    width: '100%', 
    position: 'fixed',
    bottom: 0,
    padding: 2,
    textAlign: "center",
    display: 'flex-box',
    flexDirection: 'column',
    left: 0
  }

  return (
    <>
      <Stack spacing={3} mb={10}>
      {menuItems.length == 0 && (
        <Stack alignItems='center' spacing={2}>
          <NoMealsIcon />
          <Typography variant='body1'>No available options</Typography>
        </Stack>
      )}
      {menuItems.map((menuItem) => (
        <MenuItemCard key={menuItem.id} menuItem={menuItem} />
      ))}
    </Stack>
    {cart && cart.orders.length > 0 && cart.orders[0].items.length > 0 && 
        <Box sx={boxProperties}>
          <Box mb={1}>
            <PrimaryActionButton title={'View Cart (' . concat(cart.orders[0].items.length.toString(),')')} onClick={() => { 
            goToOrder(1)
           }} />
          </Box>
        </Box>
    }
    </>
  );
};
