import { ButtonBase, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  title: string;

  subtitle?: string;

  onClick: () => void;

  disabled?: boolean;
}

export const SecondaryActionButton = ({
  title,
  subtitle,
  onClick,
  disabled = false,
}: Props) => {
  const theme = useTheme();
  return (
    <ButtonBase
      sx={{
        color: theme.palette.grey[0],
        background: disabled ? theme.palette.secondary.dark : theme.palette.primary.dark,
        border: '2px solid',
        
        borderColor: disabled ? theme.palette.secondary.dark : theme.palette.primary.dark,
        '&:hover': {
          background: theme.palette.primary.darker,
          borderColor: theme.palette.primary.darker,
        },
        width: '90%',
        maxWidth: 360,
        borderRadius: 12,
        pr: 2,
        pl: 2,
      }}
      onClick={(_) => onClick()}
      disabled={disabled}
    >
      <Stack
        direction='row'
        justifyContent={subtitle ? 'space-between' : 'center'}
        sx={{ width: '100%', padding: 2 }}
      >
        <Typography variant='body1'>
          {title}
        </Typography>
        {subtitle && (
          <Typography variant='caption'>
            {subtitle}
          </Typography>
        )}
      </Stack>
    </ButtonBase>
  );
};
